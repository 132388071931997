/**
 * business.js
 * 事業内容ページ
 */

// (1) import層
import React from "react"
// import { Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Layout, FltShopBtn, LinkButton } from "../components/index"//サイト全体のコンポーネント
import { LowerHeader, LowerTitle, LowerHeaderBg } from "../components/LowerPages/index"
// import SlideInSection from "../components/SlideInSection/SlideInSection"
import SlideInLeft from "../components/SlideInSection/SlideInLeft"
import SlideInRight from "../components/SlideInSection/SlideInRight"
// import styled from "styled-components"
import "../styles/LowerPage.scss";
import { useBreakpoint } from 'gatsby-plugin-breakpoints'


// (2) Types層

// (3) DOM層
const Business = () => {
  const title1 = [
    { "id": 0, "text": "b" },
    { "id": 1, "text": "u" },
    { "id": 2, "text": "s" },
    { "id": 3, "text": "i" },
    { "id": 4, "text": "n" },
    { "id": 5, "text": "e" },
    { "id": 6, "text": "s" },
    { "id": 7, "text": "s" }
  ];
  const breakpoints = useBreakpoint();
  return (
    <>
      <Layout className={`business`} pageName="business" title="事業内容">
        <Seo
          lang="ja"
          title={`事業内容`}
          description="BUSINESS事業内容 深川養鶏農業協同組合は、長門市を中心に、種鶏（親どり）の育成から雛の販売、鶏肉の製造・加工・販売と一貫管理を行い、肉用鶏専門の農業協同組合です。鶏の病気に対する防疫対策はもちろん、お客様に安心してお求めいただける品質の鶏肉を提供するために新しい技術を取り入れ、常に前向きに取り組んでいます。"
        />
        <div className={`LowerHeadImg`}>
          {
            breakpoints.sm ?
              <StaticImage
                layout="fullWidth"
                src="../images/business/business_header-sp.png"
                alt="business_header"
              />
              :
              <StaticImage
                layout="fullWidth"
                src="../images/business/business_header.png"
                alt="business_header"
              />

          }
        </div>
        <div className={`LowerWrapper`}>
          <LowerHeader data={title1} title={`事業内容`}>
            <LowerHeaderBg />
          </LowerHeader>
          <div className={`LowerContent SlideInWrp`}>
            <div className={`RadiusCircle`} ></div>
            <div className={`LowerLead`}>
              <p>
                深川養鶏農業協同組合は、<br className={`smBr`} />長門市を中心に<br />
                種鶏（親どり）の育成から雛の販売、<br className={`smBr`} />鶏肉の製造・加工・販売と<br />
                一貫管理を行う、肉用鶏専門の<br className={`smBr`} />農業協同組合です。<br />
                鶏の病気に対する防疫対策はもちろん、<br className={`smBr`} />お客様に安心してお求めいただける品質<br />
                の鶏肉を提供するために<br className={`smBr`} />新しい技術を取り入れ、<br />
                常に前向きに取り組んでいます。
              </p>
            </div>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`chicken egg`}>
                種鶏事業
              </LowerTitle>
              <SlideInLeft>
                <StaticImage
                  src="../images/business/business_01.png"
                  alt="種鶏事業"
                />
                <p>
                  <span>
                    長州どり、長州黒かしわの親どりとなる種鶏を専用の農場（種鶏場）で育成し、種卵を生産しています。種鶏事業は深川養鶏農協の基盤となる事業で、専門の熟練したスタッフが生産管理を行ない、種卵の安定供給を可能にしています。種鶏（親どり）が健康であることが良い種卵を生むことにもなり、ひいては良い鶏肉を作ることに繋がっています。そのためにも、常にベストな状態を維持するために、きめ細やかな管理をおこなっています。
                  </span>
                </p>

              </SlideInLeft>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`incubation`}>
                孵卵事業
              </LowerTitle>
              <SlideInRight>
                <StaticImage
                  src="../images/business/business_02.png"
                  alt="孵卵事業"
                />
                <p>
                  <span>
                    種鶏場から運ばれてきた種卵から雛を孵化させ、契約農家などに販売しています。種卵の扱いは繊細さを求められるため、コンピューターによる集中コントロール機能を搭載した孵卵器で管理されています。また、生まれたばかりの雛は弱いため、環境や衛生面を常に最適な状態に保っています。
                  </span>
                </p>
              </SlideInRight>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`bleeding`}>
                生産事業
              </LowerTitle>
              <SlideInLeft>
                <StaticImage
                  src="../images/business/business_03@2x.png"
                  alt="生産事業"
                />
                <p>
                  <span>
                    鶏のスペシャリストである「生産指導員」が育成状態を最適な状態に保つために常に連携して鶏の飼育を行っています。また、鶏の健康状態を管理するために専属獣医師が定期的に農場巡回を行なうなど、あらゆる角度で生産の維持に務めています。長門地域の契約農家を主体に法人を含む約３０戸により生産が行われており、年間約６５０万羽の鶏を飼育しています。
                  </span>
                </p>
              </SlideInLeft>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`broiler`}>
                ブロイラー事業
              </LowerTitle>
              <SlideInRight>
                <StaticImage
                  src="../images/business/business_04@2x.png"
                  alt="ブロイラー事業"
                />
                <p>
                  <span>
                    農場から出荷された鶏を食鳥検査員がチェックした後、スーパーなどで販売している製品（鶏肉）にしています。鶏肉の鮮度と品質を保つためには、鶏肉の温度が低い状態を維持しなければなりません。鶏肉を出来るだけ人の手で触らず温度が上昇しないようにオートメーションラインによる処理を行っています。包装された製品はフリーザーを通すことによりさらに0℃近くまで冷却して温度上昇を防ぎ、異物の混入検査を受けた安全な鶏肉だけが出荷されます。
                  </span>
                </p>
              </SlideInRight>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`processing`}>
                加工事業
              </LowerTitle>
              <SlideInLeft>
                <StaticImage
                  src="../images/business/business_05.png"
                  alt="種鶏事業"
                />
                <p>
                  <span>
                    「鶏の唐揚げ」や「チキン南蛮」などの揚げ物、クリスマスシーズンの「ローストチキンレッグ」を主に製造しています。なかでも「チキンスープ」は学校給食などにも使用されており、鶏ガラをふんだんに使用した鶏の生産をしているからこそ作れるメーカー直売品です。さらに、過熱蒸気焼成ラインも備えており、加熱時の油脂の酸化を抑えながらビタミンの破壊を抑制するなど、「油で揚げない唐揚げ」など身体に優しい製品も製造しています。
                  </span>
                </p>

              </SlideInLeft>
              <LinkButton link={`/product#kakou`} target={``}>
                加工品詳細
              </LinkButton>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`confectionery`}>
                製菓事業
              </LowerTitle>
              <SlideInRight>
                <StaticImage
                  src="../images/business/business_06.jpg"
                  alt="製菓事業"
                />
                <p>
                  <span>
                    山口県の銘菓として知られている「鶏卵せんべい」は深川養鶏農協のオリジナル商品です。丹念に焼き上げたカステラ風味の鶏卵せんべいは、子供からお年寄りまで長年愛され続けている自慢の一品です。他にもチーズケーキやバームクーヘン、マドレーヌなどを製造しており、贈答品やお土産としても幅広くご利用いただいています。第二工場は、米菓子の「岩おこし」「粟おこし」「生姜おこし」といった大阪に古くから伝わる銘菓を製造しています。蜜の仕込みや練り込みなどは、熟練した技術者が長年の経験をもとに、緻密な調整を加え煮詰めることで、変わらぬ味を守り続けています。
                  </span>
                </p>
              </SlideInRight>
              <div className={`LinkButton2pattern`}>
                <LinkButton link={`/product#meika`} target={``}>
                  製菓詳細
                </LinkButton>
                <LinkButton link={`https://www.instagram.com/fukawa.chosyudori/`} target={`_blank`}>
                  インスタグラム
                </LinkButton>
              </div>
            </section>

            <section className={`LowerSection`}>
              <LowerTitle subTitle={`sanitation`}>
                衛生管理
              </LowerTitle>
              <SlideInLeft>
                <StaticImage
                  src="../images/business/business_07.png"
                  alt="衛生管理"
                />
                <p>
                  <span>
                    食品を扱う工場では、食品の汚染はあってはならないことです。深川養鶏農業協同組合では、衛生的な環境で作業に従事できるように、衛生管理を専門に担当する品質管理室を設けています。品質管理室は独立した権限を持っており、食品の安全と品質を守るため、各工場の指導や衛生管理体制の確認、食品の安全検査などを通じ、食品の安全性を確保しています。
                  </span>
                </p>
              </SlideInLeft>
            </section>
          </div>
        </div>
        <FltShopBtn />
      </Layout>
    </>
  )
}

export default Business

// (4) Style層

// (5) Container層
